<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <router-link
          :to="'/jual/pelanggan/info/'+state.id"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </router-link>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate"
            >Pelanggan - Edit</span
          >
        </span>
        <a href="javascript:void(0);" class="btn px-0 d-block d-md-none">
          <i class="fa fa-home text-main"></i>
        </a>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row d-none">
          <div class="col-12">
            <div
              class="
                alert alert-danger
                bg-light
                mt-3
                mb-0
                alert-dismissible
                fade
                show
              "
              role="alert"
            >
              <button type="button" class="close" data-dismiss="alert">
                &times;
              </button>
              <strong>Alert!</strong> You should
              <a
                href="javascript:void(0);"
                class="alert-link"
                title="Click to go to this message"
                >read this message</a
              >.
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row mt-4 mb-5">
          <div class="col-12">
            <Notif />

            <Form
              @submit="update"
              :validation-schema="schema"
              v-slot="{ errors }"
              method="POST"
              class="mb-3 needs-validation"
            >
              <div class="form-row">
                <div class="col-4 col-sm-3 col-md-2 col-lg-1">
                  <div class="form-group">
                    <label for="pre"
                      >Prefix:</label
                    >
                    <Field
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.prefix }"
                      placeholder="mis: Bu, Mba, Teh, Dr., ..."
                      name="prefix"
                      v-model="state.form.prefix"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.prefix }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-8 col-sm-9 col-md-4 col-lg-5">
                  <div class="form-group">
                    <label for="nama"
                      >Nama Pelanggan: 
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.name }"
                      placeholder="Masukkan nama..."
                      name="name"
                      v-model="state.form.name"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.name }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="sel1"
                      >Grup Pelanggan:<span class="text-danger ml-1"
                        >*</span
                      ></label
                    >
                    <Field name="group" as="select" class="form-control" :class="{ 'is-invalid': errors.group }" v-model="state.form.group">
                      <option v-for="gr in state.groups" :key="gr.id" :value="gr.id" >{{ gr.name }}</option>
                    </Field>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Pilih salah satu.</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="alamat"
                      >Alamat: <span class="text-danger">*</span></label
                    >
                    <Field
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.alamat }"
                      placeholder="Masukkan alamat..."
                      name="alamat"
                      v-model="state.form.alamat"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.alamat }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="nomor"
                      >Blok-Nomor: <span class="text-danger">*</span></label
                    >
                    <Field
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.blok_no }"
                      placeholder="Masukkan blok-nomor..."
                      name="blok_no"
                      v-model="state.form.blok_no"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.blok_no }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="telepon"
                      >Telepon: 
                      <!-- <span class="text-danger">*</span> -->
                      </label>
                    <vue-tel-input :value="state.form.phone"  v-model="state.form.phone" @input="phoneInput" class="form-control" id="phone" name="phone" :class="{ 'is-invalid': errors.phone }"></vue-tel-input>

                    <Field
                      type="hidden"
                      name="phone"
                      v-model="state.form.phone"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.phone }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="email"
                      >Email: </label
                    >
                    <Field
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': errors.email }"
                      placeholder="Masukkan email..."
                      name="email"
                      v-model="state.form.email"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.email }}</div>
                  </div>
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->

              <hr class="no-side-margin" />

              <div class="row mt-3">
                <div class="col text-center">
                  <button
                    type="submit"
                    class="btn btn-main"
                    title="Simpan"
                    :disabled="state.loading"
                  >
                    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                    <i class="fa fa-save mr-2" v-else></i>Simpan
                  </button>
                  <button
                    type="reset"
                    class="btn btn-light ml-1"
                    title="Reset isian"
                  >
                    <i class="fas fa-sync-alt mr-2"></i>Reset
                  </button>
                  <a
                    href="#"
                    class="btn btn-light ml-1"
                    title="Batal"
                    @click="batal"
                    ><i class="fas fa-times mr-2"></i>Batal</a
                  >
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->
            </Form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import axios from 'axios';
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import Notif from "../../../helpers/Notif.vue";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      id: '',
      form: {
        name: "",
        prefix: "",
        group: "",
        alamat: "",
        phone: "",
        email: "",
        blok_no: "",
      },
      loading: false,
      groups: {},
    })
    const loadGroup = async () => {
      await axios.get("/api/seller/pelanggan-group").then((res) => {
        if (res.data.success) {
          state.groups = res.data.data;
        }
      });
    }
    const loadData = async () => {
      let id = route.params.id;
      await axios.get("/api/seller/pelanggan/"+id).then((res) => {
        if (res.data.success) {
          state.form.name = res.data.data.name;
          state.form.prefix = res.data.data.prefix;
          state.form.group = res.data.data.group_id;
          state.form.alamat = res.data.data.alamat;
          state.form.phone = res.data.data.phone;
          state.form.email = res.data.data.email;
          state.form.blok_no = res.data.data.blok_no;
        }
      });
    }
    onMounted(() => {
      state.id = route.params.id;
      store.dispatch("error/setDefault");
      loadGroup();
      loadData();
    })
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/pelanggan/info/"+state.id });
          }
        });
    };
    //start validate
    const schema = Yup.object().shape({
      name: Yup.string().required("Nama tidak boleh kosong"),
      // prefix: Yup.string().required("Prefix tidak boleh kosong"),
      group: Yup.string().required("Pilih Salah satu"),
      alamat: Yup.string().required("Alamat tidak boleh kosong"),
      phone: Yup.string().nullable(),
      blok_no: Yup.string().required("Blok No tidak boleh kosong"),
      // email: Yup.string()
      //   .required("Email tidak boleh kosong")
      //   .email("Email harus berupa alamat surel"),
    });
    const update = () => {
      state.loading = true;
      let id = route.params.id;
      axios.put("/api/seller/pelanggan/"+id, state.form).then((res) => {
        if(res.data.success){
          state.loading = false;
          //notif
          const notif = [];
          notif.push("Update data Berhasil!");
          store.dispatch("error/setSuccess", notif);
          loadData();
        }
      }, () => {
        state.loading = false;
      });
    }
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
      })
    }
    return {
      state,
      batal,
      schema,
      update,
      back
    }
  },
  components: {
    Form,
    Field,
    Notif,
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject?.formatted) {
        this.state.form.phone = phoneObject.formatted;
      }
    },
  },
}
</script>